import { useMutation } from '@apollo/client';
import { Button, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { User, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UPDATE_USER, UPDATE_USER_AVATAR } from '../../graphql/mutations';
import Avatar from '../Avatar/Avatar';
import UploadAvatarButton from '../Avatar/UploadAvatarButton';
import SettingsSection from '../SettingsSection/SettingsSection';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  width: 'fit-content',
  paddingX: 5,
};

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

const SettingsProfile = () => {
  const { t, i18n } = useTranslation();
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  const [uploadAvatar] = useMutation(UPDATE_USER_AVATAR, {
    onCompleted: ({ updateUserAvatar }) => {
      if (updateUserAvatar.success) {
        setSuccessMessage(t('Your avatar has been updated'));

        setUser({
          ...user,
          ...(updateUserAvatar.user as User),
        });
        return;
      }
      setErrorMessage(t('Oops! There was an error updating your avatar'));
    },
    onError: (err) => {
      setErrorMessage(t(`Profile picture couldn't be updated - ${err.message}`));
    },
  });

  const handleUploadAvatar = (avatar: string) => {
    uploadAvatar({ variables: { avatar } });
  };

  const handleRemoveAvatar = () => {
    uploadAvatar({
      variables: {
        avatar: '',
      },
    });
  };

  const [updateUserNameAndLastName] = useMutation(UPDATE_USER, {
    onError: (err) => {
      setErrorMessage(t(`Name and last name couldn't be updated - ${err.message}`));
    },
  });

  type FormName = {
    firstName: string;
    lastName: string;
  };

  const handleUpdateUserNameAndLastName = (data: FormName) => {
    const input = {
      email: user?.email || '',
      firstName: data.firstName,
      lastName: data.lastName,
      role: user?.role || [],
      plan: user?.plan || [],
      shellId: user?.shellId || '',
      shellName: user?.shellName || '',
    };
    updateUserNameAndLastName({
      variables: {
        input,
        userId: user?._id || '',
      },
      onCompleted: ({ updateUser }) => {
        if (updateUser.success) {
          setSuccessMessage(t('Your profile has been updated'));
          setUser({
            ...user,
            ...(updateUser.user as User),
          });
          return;
        }

        setErrorMessage(t('Oops! There was an error updating your profile'));
      },
      onError: (err) => {
        setErrorMessage(t(`Name and last name couldn't be updated - ${err.message}`));
      },
    });
  };

  return (
    <>
      {user?.role.includes(UserRole.Admin) && (
        <SettingsSection
          title={t('Language')}
          subtitle={t('Change your Language between English and Spanish')}
        >
          <LanguageSwitcher onChange={handleLanguageChange} />
        </SettingsSection>
      )}
      <SettingsSection
        title={t('Avatar')}
        subtitle={t(
          'You can change your avatar here or remove the current avatar. This will be your profile picture on magnettu.',
        )}
      >
        <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
          <Avatar avatar={user?.avatar || undefined} size={100} />
          <Stack direction={'column'} spacing={1} sx={{ alignItems: 'start' }}>
            <UploadAvatarButton
              setAvatar={handleUploadAvatar}
              setErrorMessage={setErrorMessage}
              buttonProps={{ variant: 'contained', sx: buttonStyle }}
            />
            {user?.avatar && (
              <Button variant="outlined" onClick={handleRemoveAvatar} sx={buttonStyle}>
                {t('Remove avatar')}
              </Button>
            )}
          </Stack>
        </Stack>
      </SettingsSection>
      <SettingsSection title={t('User information')} subtitle="">
        <FormContainer
          defaultValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          }}
          onSuccess={(data) => {
            handleUpdateUserNameAndLastName(data);
          }}
        >
          <Stack sx={{ mb: 3, ...inputStyle }} direction="column" spacing={2}>
            <TextFieldElement name="firstName" label={t('First name')} required />
            <TextFieldElement name="lastName" label={t('Last name')} required />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            sx={buttonStyle}
            data-cy="submit-button"
          >
            {t('Save')}
          </Button>
        </FormContainer>
      </SettingsSection>
    </>
  );
};

export default SettingsProfile;
