import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { MetricByUserResponse, MetricByWeek } from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import GraphicReportCompanyUserImpressionsForWeek from '../Reports/GraphicReportCompanyUserImpressionsForWeek';
import GraphicReportCompanyUserInteractionsForWeek from '../Reports/GraphicReportCompanyUserInteractionsForWeek';
import { useTranslation } from 'react-i18next';
import DatePickerRange from '../DatePicker/DatePickerRange';

interface Props {
  data: MetricByUserResponse;
  dateFilter: (dateInit: string, dateEnd: string) => void;
  dateInit: Date;
  dateEnd: Date;
  loadingButton: boolean;
  metricsByWeek: MetricByWeek[];
  differenceInDays: number;
}

const IndividualDashboard = ({
  data,
  dateFilter,
  dateInit,
  dateEnd,
  loadingButton,
  metricsByWeek,
  differenceInDays,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DatePickerRange
        dateInit={dateInit}
        dateEnd={dateEnd}
        dateFilter={dateFilter}
        loadingButton={loadingButton}
      />

      {loadingButton ? (
        <CircularProgress
          sx={{
            display: 'block',
            margin: 'auto',
          }}
        />
      ) : (
        <>
          {!data.haveMetricsFirst30Days && (
            <Alert severity="error" sx={{
              marginBottom: '1rem',
            }}>
              No have metrics from {dateInit.toLocaleDateString()} to {dateEnd.toLocaleDateString()}. Please select another date range.
            </Alert>
          )}
          <Box
            display="flex"
            gap={2}
            alignContent="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Connections')}
                // differenceInDays={differenceInDays}
                lastDays={`${t('in the last')} ${differenceInDays} ${t('days')}`}
                subtitle={false}
                comparation={formatPostsCountDifference(
                  data.followers,
                  data.followers30DaysAgo,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {data.followers ? data.followers.toLocaleString('es-ES') : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('contacts + followers')}
                </Typography>
              </CardReport>
            </Box>
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Activity')}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  data.countMetrics30Days,
                  data.countMetrics60Days,
                )}
                differenceInDays={differenceInDays}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {data.countMetrics30Days}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('posts')}
                </Typography>
              </CardReport>
            </Box>
            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Impressions')}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                differenceInDays={differenceInDays}
                comparation={formatPostsCountDifference(
                  data.impressions30DaysAgo,
                  data.impressions60DaysAgo,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {!!data.metricsByWeek?.length
                    ? data.impressions30DaysAgo.toLocaleString('es-ES')
                    : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('impressions')}
                </Typography>
              </CardReport>
            </Box>

            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Interactions')}
                differenceInDays={differenceInDays}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  data.interactions30DaysAgo,
                  data.interactions60DaysAgo,
                )}
              >
                <Typography fontSize="2rem" textAlign="center" mr="5px">
                  {!!data.metricsByWeek?.length
                    ? data.interactions30DaysAgo.toLocaleString('es-ES')
                    : 'N/A'}
                </Typography>
                <Typography fontSize="1rem" textAlign="center">
                  {' '}
                  {t('interactions')}
                </Typography>
              </CardReport>
            </Box>

            <Box width={{ sm: '50%', md: '20%' }}>
              <CardReport
                title={t('Engagement')}
                differenceInDays={differenceInDays}
                lastDays={`${t('vs the previous')} ${differenceInDays} ${t('days')}`}
                subtitle
                comparation={formatPostsCountDifference(
                  (data.interactions30DaysAgo / data.impressions30DaysAgo) * 100,
                  (data.interactions60DaysAgo / data.impressions60DaysAgo) * 100,
                )}
              >
                <Box>
                  <Typography
                    marginTop="1rem"
                    fontSize="2rem"
                    textAlign="center"
                    mr="5px"
                  >
                    {data.interactions30DaysAgo && data.impressions30DaysAgo
                      ? `${(
                          (data.interactions30DaysAgo / data.impressions30DaysAgo) *
                          100
                        ).toFixed(2)}% `
                      : 'N/A'}
                  </Typography>
                  <Typography fontSize="1rem" textAlign="center">
                    {' '}
                    {t('audience reaction')}
                  </Typography>
                </Box>
              </CardReport>
            </Box>
          </Box>
        </>
      )}
      {!!metricsByWeek?.length && (
        <>
          <GraphicReportCompanyUserImpressionsForWeek
            data={metricsByWeek as MetricByWeek[]}
          />
          <GraphicReportCompanyUserInteractionsForWeek
            data={metricsByWeek as MetricByWeek[]}
          />
        </>
      )}
    </>
  );
};

export default IndividualDashboard;
