import {
  AutoAwesome,
  EditOutlined,
  PlaylistAdd,
  ReadMore,
  Translate as TranslateIcon,
  Spellcheck as SpellcheckIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import AIAssistantModal from './AIAssistantModal';
import { AuthContext } from '../../context/AuthContext';
import { Tag } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

export const AIAssistantActions: Tag[] = [
  {
    name: 'GENERATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'COMPLETE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'REWRITE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'ASK_MAG',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'TRANSLATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'CORRECT_GRAMMAR_SPELL',
    color: '#FFFFFF',
    internal: true,
  },
];

interface Props {
  postId?: string;
  editor: Editor;
  onLoading: (loading: boolean) => void;
  onChange: (content: string, json: any, action?: Tag) => void;
}

const AIAssistantMenu = ({ postId, editor, onChange, onLoading = () => {} }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);

  const [aiAssistOpen, setAiAssistOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorElAiMenu, setAnchorElAiMenu] = useState<HTMLButtonElement | null>(null);
  const [translateMenuAnchorEl, setTranslateMenuAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const cursorPositionRef = useRef<number>(editor.state.selection.anchor);

  const menuOpen = Boolean(anchorElAiMenu);
  const translateMenuOpen = Boolean(translateMenuAnchorEl);
  const content = editor.getText({ blockSeparator: '\n' });

  const [fetchDataCompleteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[1]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataRewriteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[2]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataTranslateContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[4]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataCorrectGrammarSpell] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[5]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const handleCompleteContent = async () => {
    onLoading(true);
    setLoading(true);
    editor.commands.focus('end');
    cursorPositionRef.current = editor.state.selection.anchor;
    fetchDataCompleteContent({
      input: content,
      chainId: '66310b7bf1de1c64651db3a1',
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
    });
  };

  const handleRewriteContent = async () => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataRewriteContent({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId: '66310b98f1de1c64651db3a3',
      userId: user?._id || undefined,
    });
  };

  const handleTranslateContent = async (language: string) => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataTranslateContent({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId:
        language === 'en' ? '66fa5e131dc63fe14d6b8e74' : '66fa872c1dc63fe14d6b8e76',
      userId: user?._id || undefined,
    });
  };

  const handleCorrectGrammarSpell = async () => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataCorrectGrammarSpell({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId: '66fa72dc1dc63fe14d6b8e75',
      userId: user?._id || undefined,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    editor.setOptions({
      editable: false,
    });

    setAnchorElAiMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElAiMenu(null);

    editor.setOptions({
      editable: true,
    });
  };

  const handleTranslateMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTranslateMenuAnchorEl(event.currentTarget);
  };

  const handleTranslateMenuClose = () => {
    setTranslateMenuAnchorEl(null);
  };

  const text = {
    fontSize: '14px',
  };

  return (
    <>
      <AIAssistantModal
        open={aiAssistOpen}
        onClose={() => setAiAssistOpen(false)}
        onAddText={(content) => {
          editor.commands.insertContent(
            '<p>' + content.replaceAll('\n', '<br>') + '</p>',
          );
          editor.setEditable(true);
          onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[0]);
        }}
      />
      {isMobile ? (
        <IconButton
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <AutoAwesome />
        </IconButton>
      ) : (
        <LoadingButton
          variant="outlined"
          sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
          startIcon={<AutoAwesome />}
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
          loadingPosition="start"
          loading={loading}
        >
          {t('Use AI')}
        </LoadingButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorElAiMenu}
        open={menuOpen}
        onClose={handleClose}
        elevation={1}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setAiAssistOpen(true);
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Generate content')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCompleteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <ReadMore fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Complete content')}
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleRewriteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <EditOutlined fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Rewrite content')}
          </ListItemText>
        </MenuItem>

        <MenuItem
          disabled={content.length === 0}
          onClick={handleTranslateMenuOpen} // Open the submenu when "Translate content" is clicked
          disableRipple
        >
          <ListItemIcon>
            <TranslateIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Translate content')}
          </ListItemText>
        </MenuItem>

        <Popover
          id="translate-menu"
          open={translateMenuOpen}
          anchorEl={translateMenuAnchorEl}
          onClose={handleTranslateMenuClose} // Close the submenu when clicking outside
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disablePortal
        >
          <MenuItem
            onClick={() => {
              handleTranslateContent('en');
              handleTranslateMenuClose(); // Close submenu when clicking on an option
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>English</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleTranslateContent('es');
              handleTranslateMenuClose(); // Close submenu when clicking on an option
            }}
            disableRipple
          >
            <ListItemText primaryTypographyProps={{ style: text }}>Spanish</ListItemText>
          </MenuItem>
        </Popover>

        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCorrectGrammarSpell();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <SpellcheckIcon fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            {t('Correct grammar and spell')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AIAssistantMenu;
