import { useMutation } from '@apollo/client';
import { Favorite, Search } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Card, InputAdornment, Menu, MenuItem, Stack, Chip } from '@mui/material';
import { useContext, useState } from 'react';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { FrequencyFilters, InsightType } from '../../__generated__/graphql';
import { GET_CURRENT_USER, GET_INSIGHTS } from '../../graphql/queries';
import { UPDATE_BRAND_INSIGHTS_TOPICS } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import TopicSelectorModal from './TopicSelectorModal'; // Import the modal from the second component

export interface InsightsFiltersData {
  topics: string[];
  authors: string[];
  favorites: boolean;
  frequency: FrequencyFilters;
  insightTypes: InsightType[];
}

export const InsightsFilters = ({
  filters,
  onFiltersChange,
  dateFilters,
}: {
  filters: InsightsFiltersData;
  onFiltersChange: (filters: InsightsFiltersData) => void;
  dateFilters: boolean;
}) => {
  const { t } = useTranslation();
  const [anchorModalElem, setAnchorModalElem] = useState<HTMLButtonElement | null>(null);
  const [topicModalOpen, setTopicModalOpen] = useState<boolean>(false); // State for the modal
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for the publication date menu
  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyFilters | null>(
    null,
  );
  const { user } = useContext(AuthContext);

  const [updateUserInsights] = useMutation(UPDATE_BRAND_INSIGHTS_TOPICS, {
    refetchQueries: [GET_CURRENT_USER],
  });

  // Updated function to handle both topics and authors
  const handleUpdateSearch = (values: string[]) => {
    const uniqueValues = Array.from(new Set(values));

    if (filters.insightTypes.includes(InsightType.Linkedin)) {
      // Clear topics and update authors
      onFiltersChange({
        ...filters,
        topics: [],
        authors: uniqueValues, // Add authors and display them in the search bar
      });
    } else {
      // Clear authors and update topics
      onFiltersChange({
        ...filters,
        authors: [],
        topics: uniqueValues, // Add topics and display them in the search bar
      });
    }

    updateUserInsights({
      variables: {
        brandId: user?.brandSelectedId!,
        topics: uniqueValues,
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_INSIGHTS],
    });
  };

  const handleSwitchToLinkedIn = () => {
    onFiltersChange({
      ...filters,
      insightTypes: [InsightType.Linkedin],
      topics: [], // Clear topics when switching to LinkedIn
    });
  };

  const handleSwitchToNews = () => {
    onFiltersChange({
      ...filters,
      insightTypes: [InsightType.News],
      authors: [], // Clear authors when switching to News
    });
  };

  const handleTopicModalClick = (event: any) => {
    setAnchorModalElem(event.currentTarget);

    if (filters.favorites || filters.insightTypes.includes(InsightType.Linkedin)) {
      return;
    }

    setTopicModalOpen(true);
  };

  const handleDateButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelect = (period: FrequencyFilters) => {
    onFiltersChange({
      ...filters,
      frequency: period,
    });
    setSelectedFrequency(period);
    setAnchorEl(null); // Close the menu after selection
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        padding: '17px 25px',
        width: '100%',
        borderRadius: '8px',
        marginBottom: '1rem',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between" // Spread items across the row
        alignItems="center" // Align vertically in the center
        width="100%"
        mb={2} // Margin to space between rows
      >
        {/* Left side: LinkedIn and News buttons */}
        <Stack direction="row" spacing={1}>
          <Button
            variant={
              filters.insightTypes.includes(InsightType.Linkedin)
                ? 'contained'
                : 'outlined'
            }
            onClick={handleSwitchToLinkedIn}
          >
            LinkedIn
          </Button>

          <Button
            variant={
              filters.insightTypes.includes(InsightType.News) ? 'contained' : 'outlined'
            }
            onClick={handleSwitchToNews}
          >
            News
          </Button>
        </Stack>

        {/* Right side: Search field and Favorite button */}
        <Stack direction="row" spacing={1} alignItems="center">
          {filters.insightTypes.includes(InsightType.Linkedin) ? (
            <FormContainer values={{ authors: filters.authors }}>
              <AutocompleteElement
                name="authors"
                data-testid="authors-input"
                multiple
                options={[]}
                textFieldProps={{
                  variant: 'outlined',
                  onClick: handleTopicModalClick,
                  placeholder: t('Search new author'),
                  disabled: filters.favorites,
                  InputLabelProps: {
                    shrink: true,
                    disabled: true,
                  },
                  InputProps: {
                    sx: {
                      minWidth: '300px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#c4c4c4',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#c4c4c4',
                        borderWidth: 1,
                      },
                      borderRadius: '8px',
                    },
                    ...(false || filters.authors.length === 0 || filters.favorites
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search sx={{ marginLeft: '12px' }} />
                            </InputAdornment>
                          ),
                        }
                      : undefined),
                  },
                }}
                autocompleteProps={{
                  disableClearable: true,
                  disableCloseOnSelect: true,
                  disabled: filters.favorites,
                  onBlur: () => {},
                  freeSolo: true,
                  clearIcon: null,
                  limitTags: 6,

                  onChange: (_, value) => {
                    handleUpdateSearch(value);
                  },
                  sx: {
                    minWidth: { xs: '100%', sm: '450px' },
                    '& .MuiAutocomplete-inputRoot': {
                      height: { xs: 'auto', md: '42px !important' },
                      padding: '0px !important',
                    },
                  },
                  renderTags: (authors, getTagProps) => {
                    const searchIcon = (
                      <Search
                        sx={{
                          color: 'rgba(0, 0, 0, 0.56)',
                        }}
                      />
                    );

                    return [
                      <div
                        key="search-icon"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '12px',
                        }}
                      >
                        {searchIcon}
                      </div>,
                      ...(!topicModalOpen
                        ? authors.map((option, index) => (
                            <Chip
                              sx={{
                                background: 'white',
                                height: '30px',
                                border: '1px solid rgba(0, 122, 255, 1)', // Changed to blue
                                padding: '0px 5px',
                                '& .MuiChip-label': {
                                  color: 'rgba(0, 122, 255, 1)', // Changed to blue
                                  background: 'white',
                                },
                                '& .MuiChip-deleteIcon': {
                                  color: 'rgba(0, 122, 255, 1)', // Changed to blue
                                },
                              }}
                              label={option}
                              {...getTagProps({ index })}
                              onDelete={() => {
                                handleUpdateSearch(authors.filter((a) => a !== option));
                              }}
                              key={index}
                            />
                          ))
                        : []),
                    ];
                  },
                }}
              />
            </FormContainer>
          ) : (
            <FormContainer values={{ topics: filters.topics }}>
              <AutocompleteElement
                name="topics"
                data-testid="topics-input"
                multiple
                options={[]}
                textFieldProps={{
                  variant: 'outlined',
                  onClick: handleTopicModalClick,
                  placeholder:
                    filters.topics.length === 0 || topicModalOpen
                      ? t('Search new topic')
                      : '',
                  disabled: filters.favorites,
                  InputLabelProps: {
                    shrink: true,
                    disabled: true,
                  },
                  InputProps: {
                    sx: {
                      minWidth: '300px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#c4c4c4',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#c4c4c4',
                        borderWidth: 1,
                      },
                      borderRadius: '8px',
                    },
                    ...(false || filters.topics.length === 0 || filters.favorites
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search sx={{ marginLeft: '12px' }} />
                            </InputAdornment>
                          ),
                        }
                      : undefined),
                  },
                }}
                autocompleteProps={{
                  disableClearable: true,
                  disableCloseOnSelect: true,
                  disabled: filters.favorites,
                  onBlur: () => {},
                  freeSolo: true,
                  clearIcon: null,
                  limitTags: 6,

                  onChange: (_, value) => {
                    handleUpdateSearch(value);
                  },
                  sx: {
                    minWidth: { xs: '100%', sm: '450px' },
                    '& .MuiAutocomplete-inputRoot': {
                      height: { xs: 'auto', md: '42px !important' },
                      padding: '0px !important',
                    },
                  },
                  renderTags: (topics, getTagProps) => {
                    const searchIcon = (
                      <Search
                        sx={{
                          color: 'rgba(0, 0, 0, 0.56)',
                        }}
                      />
                    );

                    return [
                      <div
                        key="search-icon"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '12px',
                        }}
                      >
                        {searchIcon}
                      </div>,
                      ...(!topicModalOpen
                        ? topics.map((option, index) => (
                            <Chip
                              sx={{
                                background: 'white',
                                height: '30px',
                                border: '1px solid rgba(255, 0, 122, 1)',
                                padding: '0px 5px',
                                '& .MuiChip-label': {
                                  color: 'rgba(255, 0, 122, 1)',
                                  background: 'white',
                                },
                                '& .MuiChip-deleteIcon': {
                                  color: 'rgba(255, 0, 122, 1)',
                                },
                              }}
                              label={option.startsWith('#') ? option : `#${option}`}
                              {...getTagProps({ index })}
                              onDelete={() => {
                                handleUpdateSearch(topics.filter((t) => t !== option));
                              }}
                              key={index}
                            />
                          ))
                        : []),
                    ];
                  },
                }}
              />
            </FormContainer>
          )}

          {/* Favorite button */}
          <Button
            data-testid="favorite-button"
            sx={{
              border: filters.favorites ? '1px solid #FF007A' : '1px solid #BBBBBB',
              color: filters.favorites ? '#FF007A' : 'rgba(0, 0, 0, 0.56)',
              backgroundColor: filters.favorites ? 'rgba(255, 0, 122, 0.08)' : 'white',
              minWidth: '42px',
              width: '42px',
              height: '42px',
              padding: '0px important!',
            }}
            variant={filters.favorites ? 'contained' : 'outlined'}
            onClick={() => onFiltersChange({ ...filters, favorites: !filters.favorites })}
          >
            <Favorite data-testid="favorite-filled-icon" sx={{ width: '20px' }} />
          </Button>
        </Stack>
      </Stack>

      {/* Second row: Publication Date button */}
      <Stack direction="row" justifyContent="flex-start" width="100%" mt={2}>
        <Button
          aria-controls="publication-date-menu"
          aria-haspopup="true"
          onClick={handleDateButtonClick}
          variant="outlined"
          endIcon={<FilterListIcon />} // Add the filter icon to the right
          sx={{
            borderColor: '#D3D3D3', // Light gray border color
            color: '#A9A9A9', // Light gray text color
            textTransform: 'none', // Keep the text normal case (not uppercase)
            '&:hover': {
              borderColor: '#A9A9A9', // Darker gray border on hover
            },
          }}
        >
          Publication Date
        </Button>
        <Menu
          id="publication-date-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Day)}
            selected={selectedFrequency === FrequencyFilters.Day}
          >
            {t('Last Day')}
          </MenuItem>
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Week)}
            selected={selectedFrequency === FrequencyFilters.Week}
          >
            {t('Last Week')}
          </MenuItem>
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Month)}
            selected={selectedFrequency === FrequencyFilters.Month}
          >
            {t('Last Month')}
          </MenuItem>
        </Menu>
      </Stack>

      {/* Add the TopicSelectorModal here */}
      <TopicSelectorModal
        open={topicModalOpen}
        anchorElement={anchorModalElem!}
        onClose={() => setTopicModalOpen(false)}
        topics={filters.topics}
        onTopicsChange={handleUpdateSearch} // Update the topics when the modal closes
      />
    </Card>
  );
};
