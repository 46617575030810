import { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { usePageTracking } from '..';
import { UserRole } from '../__generated__/graphql';
import { AuthContext } from '../context/AuthContext';
import { BrandContext } from '../context/BrandContext';
import { SnackbarContext } from '../context/SnackbarContext';
import usePageConfig from '../hooks/usePageConfig';
import { FormProvider } from '../lead-magnets/contexts/FormContext';
import AdvocacyManager from './AdvocacyManager/AdvocacyManager';
import Analytics from './Analytics/Analytics';
import Brand from './Brands/Brands';
import BrandsActivityScreen from './BrandsActivity/BrandsActivity';
import Calendar from './Calendar/Calendar';
import ConnectLinkedin from './ConnectLinkedin/ConnectLinkedin';
import ContentBoard from './ContentBoard/ContentBoard';
import ContentManagerAdmin from './ContentManager/ContentManagerAdmin';
import CreateChain from './CreateChain/CreateChain';
import CreatePost from './CreatePost/CreatePost';
import CreateShell from './CreateShell/CreateShell';
import CreateUser from './CreateUser/CreateUser';
import Dashboard from './Dashboard/Dashboard';
import GroupsMembers from './GroupsMembers/GroupsMembers';
import Insights from './Insights/Insights';
import DownloadVideo from './LeadMagnets/DownloadVideo';
import GeneratePostTheme from './LeadMagnets/GeneratePostTheme';
import Isprox from './LeadMagnets/Isprox';
import JobDescription from './LeadMagnets/JobDescription';
import RealEstateDescription from './LeadMagnets/RealEstateDescription';
import Login from './Login/Login';
import ManageTeam from './ManageTeam/ContentManager';
import ResetPassword from './PasswordReset/PasswordReset';
import PerformingContent from './PerformingContent/PerformingContent';
import PostEditorScreen from './PostEditor/PostEditor';
import BatchPrompter from './Prompter/BatchPrompter';
import Prompter from './Prompter/Prompter';
import RunDetail from './Prompter/RunDetail';
import Settings from './Settings/Settings';
import ShellPanel from './ShellPanel/ShellPanel';
import Signup from './Signup/Signup';
import UsersPanel from './UserPanel/UsersPanel';
import { useTranslation } from 'react-i18next';

export const RequireAuth = ({
  role,
  children,
}: {
  role?: UserRole | UserRole[];
  children: JSX.Element;
}) => {
  const location = useLocation();

  const { user, loading } = useContext(AuthContext);

  if (loading) return null;

  const excluded = ['/login', '/signup', '/reset-password'];
  if (!user && !excluded.includes(location.pathname)) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (role) {
    const roles = Array.isArray(role) ? role : [role];
    if (!roles.some((r) => user?.role.includes(r))) {
      return <Navigate to="/" state={{ from: location }} />;
    }
  }

  return children;
};

const PageTracker = () => {
  usePageTracking();
  return null; // This component doesn't render anything
};

const App = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Redirect to non-www domain to avoid CORS issues
    if (window.location.hostname.startsWith('www')) {
      window.location.href = 'https://' + window.location.hostname.replace('www.', '');
    }
  }, []);

  const {
    fetchLinkedinOrganizationPages,
    fetchLinkedinProfile,
    handleModalNewBrandFromCallback,
    loading,
    isReadyForModal,
  } = useContext(BrandContext);
  const { setErrorMessage } = useContext(SnackbarContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!loading && searchParams.has('linkedinToken')) {
      const token = searchParams.get('linkedinToken');
      const isModal =
        searchParams.has('isModal') && searchParams.get('isModal') === 'true';
      const organization =
        searchParams.has('organization') && searchParams.get('organization') === 'true';
      const userAvatar = searchParams.get('userAvatar') || undefined;

      if (isModal && isReadyForModal) {
        handleModalNewBrandFromCallback(token!, userAvatar || '');
      } else {
        if (organization) {
          fetchLinkedinOrganizationPages(token!);
        } else {
          fetchLinkedinProfile(token!, userAvatar || '');
        }
      }
      window.history.replaceState({}, '', window.location.pathname);
    }

    if (searchParams.has('error')) {
      setErrorMessage(t('Error connecting to LinkedIn. Please try again.'));
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [
    isReadyForModal,
    fetchLinkedinOrganizationPages,
    fetchLinkedinProfile,
    handleModalNewBrandFromCallback,
    setErrorMessage,
    loading,
    t,
  ]);

  const titleMagnettu = t(
    "magnettu - Sell more through to your employees' personal brand. magnettu is an AI advocacy tool that allows the personality of your employees do the selling for you!",
  );

  const { user } = useContext(AuthContext);

  const title: string = user
    ? user?.shell?.whiteLabel
      ? user?.shell?.title || ''
      : titleMagnettu
    : '';
  const icon: string = user
    ? user?.shell?.whiteLabel
      ? user?.shell?.icon || ''
      : './images/icons/favicon.ico'
    : '';

  usePageConfig(title, icon);

  let brandKey = user?.brandSelectedId;

  return (
    <BrowserRouter>
      <PageTracker />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/connect-linkedin/:brandId" element={<ConnectLinkedin />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/post/:postId" element={<PostEditorScreen />} />
        <Route
          path="/lm/job-description"
          element={
            <FormProvider>
              <JobDescription />
            </FormProvider>
          }
        />
        <Route
          path="/lm/isprox"
          element={
            <FormProvider>
              <Isprox />
            </FormProvider>
          }
        />
        <Route
          path="/lm/realestate-description"
          element={
            <FormProvider>
              <RealEstateDescription />
            </FormProvider>
          }
        />
        <Route
          path="/lm/download-video"
          element={
            <FormProvider>
              <DownloadVideo />
            </FormProvider>
          }
        />
        <Route
          path="/lm/generate-post-theme"
          element={
            <FormProvider>
              <GeneratePostTheme />
            </FormProvider>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route
            path=""
            element={
              user?.role.includes(UserRole.BrandManager) ||
              user?.role.includes(UserRole.LimitedBrandManager) ? (
                <Navigate to="/manage-team" replace />
              ) : (
                <ContentBoard key={brandKey} />
              )
            }
          />
          <Route path="insights" element={<Insights key={brandKey} />} />
          <Route
            path="calendar"
            element={
              user?.role.includes(UserRole.BrandManager) ||
              user?.role.includes(UserRole.LimitedBrandManager) ? (
                <Navigate to="/manage-team" replace />
              ) : (
                <Calendar key={brandKey} />
              )
            }
          />
          <Route path="analytics" element={<Analytics key={brandKey} />} />
          <Route path="settings" element={<Settings />} />
          <Route path="new-post" element={<PostEditorScreen />} />

          <Route
            path="groups-members"
            element={
              <RequireAuth role={UserRole.GroupMembersEditor}>
                <GroupsMembers />
              </RequireAuth>
            }
          />
          <Route
            path="manage-team"
            element={
              <RequireAuth role={[UserRole.BrandManager, UserRole.LimitedBrandManager]}>
                <ManageTeam />
              </RequireAuth>
            }
          />
          <Route
            path="brands"
            element={
              <RequireAuth role={[UserRole.BrandManager, UserRole.LimitedBrandManager]}>
                <Brand />
              </RequireAuth>
            }
          />
          <Route
            path="brands-activity"
            element={
              <RequireAuth role={[UserRole.BrandManager, UserRole.LimitedBrandManager]}>
                <BrandsActivityScreen />
              </RequireAuth>
            }
          />
          <Route
            path="top-content"
            element={
              <RequireAuth role={[UserRole.BrandManager, UserRole.LimitedBrandManager]}>
                <PerformingContent />
              </RequireAuth>
            }
          />
          <Route
            path="clients"
            element={
              <RequireAuth role={UserRole.Admin}>
                <ShellPanel />
              </RequireAuth>
            }
          />
          <Route
            path="clients/new"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateShell />
              </RequireAuth>
            }
          />
          <Route
            path="clients/edit/:clientId"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateShell />
              </RequireAuth>
            }
          />
          <Route
            path="users"
            element={
              <RequireAuth role={UserRole.Admin}>
                <UsersPanel />
              </RequireAuth>
            }
          />

          <Route
            path="users/new"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateUser />
              </RequireAuth>
            }
          />
          <Route
            path="users/edit/:userId"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateUser />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager"
            element={
              <RequireAuth role={UserRole.Admin}>
                <ContentManagerAdmin />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/new-post"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreatePost />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/edit-post/:postId"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreatePost />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/prompter-runs/:runId"
            element={
              <RequireAuth role={UserRole.Admin}>
                <RunDetail />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/prompter"
            element={
              <RequireAuth role={UserRole.Admin}>
                <Prompter />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/batch-prompter"
            element={
              <RequireAuth role={UserRole.Admin}>
                <BatchPrompter />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/new-chain"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateChain />
              </RequireAuth>
            }
          />
          <Route
            path="content-manager/chains/:chainId"
            element={
              <RequireAuth role={UserRole.Admin}>
                <CreateChain />
              </RequireAuth>
            }
          />
          <Route
            path="advocacy"
            element={
              <RequireAuth role={UserRole.AdvocacyManager}>
                <AdvocacyManager />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
