import { Box, Button, Card, Dialog, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import SettingsProfile from '../../components/Settings/SettingsProfile';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import SettingsNotifications from '../../components/Settings/SettingsNotifications';
import BrandForm from '../../components/BrandComponents/BrandForm';
import BrandSettingsModal from '../../components/BrandComponents/BrandSettingsModal';
import { BrandContext } from '../../context/BrandContext';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { setBrandSelected } = useContext(BrandContext);

  const handleOpenModal = () => {
    setBrandSelected(null);
    setOpenModal(true);
  };

  const handleTabChange = (_: any, newValue: number) => {
    setTab(newValue);
  };

  return (
    user && (
      <>
        <Stack direction="column" p={isMobile ? '40px 30px' : '40px 25%'} spacing={4}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ borderBottom: 1, borderRadius: 0, borderColor: 'grey.100' }}
          >
            <Tab
              label={t('Brand Settings')}
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            />
            <Tab
              label={t('Profile Settings')}
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            />
            <Tab
              label={t('Notifications')}
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            />
          </Tabs>
          <TabPanel value={tab} index={0} sx={{ p: 0 }}>
            {!user.brand ? (
              <>
                <Box
                  sx={{
                    borderRadius: '20px',
                    padding: '20px',
                    width: '100%',
                    height: '70vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <img
                    src="../images/cajaBrand.png"
                    alt="onboarding"
                    style={{ width: '85px', height: '85px' }}
                  />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      marginTop: '10px',
                    }}
                    width={{ xs: '100%', sm: '100%', md: '50%' }}
                  >
                    {t(
                      'It’s time to set up your brand. By establishing your own brand and entering the necessary details, you’ll streamline your workflow and leverage our tools to their fullest potential. Please create your brand to begin optimizing your operations.',
                    )}
                  </Typography>

                  <Button variant="contained" onClick={handleOpenModal}>
                    {t('Create your brand')}
                  </Button>
                </Box>

                <Dialog
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  fullScreen={isMobile}
                  PaperProps={{
                    sx: {
                      width: '800px',
                      maxWidth: '800px !important',
                    },
                  }}
                >
                  <BrandSettingsModal onClose={() => setOpenModal(false)} />
                </Dialog>
              </>
            ) : (
              <Card
                sx={{
                  padding: '20px',
                  borderRadius: '8px',
                }}
              >
                <BrandForm
                  brandSelected={user.brand}
                  isBrandPage={false}
                  isModal={false}
                />
              </Card>
            )}
          </TabPanel>
          <TabPanel value={tab} index={1} sx={{ p: 0 }}>
            <SettingsProfile />
          </TabPanel>
          <TabPanel value={tab} index={2} sx={{ p: 0 }}>
            <SettingsNotifications />
          </TabPanel>
        </Stack>
      </>
    )
  );
};

export default Settings;
