import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import AvatarCell from '../Avatar/AvatarCell';
import { Brand, User, UserRole } from '../../__generated__/graphql';
import { getFullName } from '../../utils';
import Delete from '@mui/icons-material/Delete';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_BRAND_MANAGER_FROM_BRAND } from '../../graphql/mutations';
import { BrandContext } from '../../context/BrandContext';
import { InfoOutlined } from '@mui/icons-material';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useTranslation } from 'react-i18next';


interface Props {
  listManagers: User[];
  brandSelected: Brand;
}

const BrandManagertListTable = ({ listManagers, brandSelected }: Props) => {
  const { t } = useTranslation();
  // Context
  const { refetch } = useContext(BrandContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);

  // State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState<User | null>(null);

  // Mutation
  const [deleteManagerFromBrand] = useMutation(DELETE_BRAND_MANAGER_FROM_BRAND);

  const handleOpenModal = (manager: User) => {
    setSelectedManager(manager);
    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
    setSelectedManager(null);
  };

  const handleDelete = () => {
    if (selectedManager) {
      deleteManagerFromBrand({
        variables: {
          brandId: brandSelected._id,
          userId: selectedManager._id,
        },
        onCompleted: (data) => {
          if (data.deleteBrandManagerFromBrand?.success) {
            setSuccessMessage('Manager removed successfully');
            refetch();
            return;
          }
          setErrorMessage('Error removing manager');
        },
      });
    }
    handleCloseModal();
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Table
          sx={{
            width: '100%',
          }}
        >
          <TableHead></TableHead>
          <TableBody>
            {listManagers?.map((manager) => (
              <TableRow
                key={manager._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: 'white',
                }}
                data-testid="user-row"
              >
                <AvatarCell
                  avatar={''}
                  name={getFullName(manager)}
                  backgroundColor={'#F8C9E3'}
                  email={manager.email}
                />
                <TableCell>{manager.firstName && manager.email}</TableCell>
                <TableCell>
                  {manager.role.includes(UserRole.BrandManager) ? (
                    <>
                      Brand Manager
                      <Tooltip title={t('This Brand Manager handles all brands, you can change his role from the Members page.')}>
                        <InfoOutlined sx={{ ml: 1 }} fontSize="small" />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      Limited Brand Manager
                      <Tooltip title="This Limited Brand Manager handles specific brands, so you can remove them from this and any other brand they're in charge of.">
                        <InfoOutlined sx={{ ml: 1 }} fontSize="small" />
                      </Tooltip>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {manager.role.includes(UserRole.LimitedBrandManager) && manager._id !== brandSelected.createdById
                   && (
                    <Delete
                      onClick={(e) => {
                        handleOpenModal(manager);
                      }}
                      
                      sx={{
                        color: '#C62828',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDeleteModal}
        onClose={handleCloseModal}
        title={`Remove ${getFullName(selectedManager)}`}
        content="Are you sure you want to remove this manager?"
        onConfirm={handleDelete}
        okText="Remove"
        deleteButton
      />
    </>
  );
};

export default BrandManagertListTable;
