import { useMutation } from '@apollo/client';
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Link,
  Snackbar,
  Toolbar,
  Zoom,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserPlan } from '../../__generated__/graphql';
import NavBar from '../../components/NavBar.tsx/NavBar';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { REQUEST_UPGRADE } from '../../graphql/mutations';
import { RoutesNames } from '../../types';
import { getDrawerWidth } from '../../utils';
import { useTranslation } from 'react-i18next';

interface customSnackbarProps {
  severity: AlertColor;
  message: string | null;
  handleClose: () => void;
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState<RoutesNames>('');
  const { user } = useContext(AuthContext);
  const { isMobile, isSmallDevice } = useContext(DeviceContext);
  const [openNavbar, setOpenNavbar] = useState(!isSmallDevice);
  const drawerWidth = getDrawerWidth(isMobile, isSmallDevice, openNavbar);
  const snackbar = useContext(SnackbarContext);
  const [requestUpgrade] = useMutation(REQUEST_UPGRADE);

  useEffect(() => {
    setActive(pathname.split('/')[1] as RoutesNames);
  }, [pathname]);

  const snackbarTypes = [
    { severity: 'success', message: snackbar.successMessage },
    { severity: 'error', message: snackbar.errorMessage },
    { severity: 'warning', message: snackbar.warningMessage },
  ];

  const handleRequestUpgrade = () => {
    requestUpgrade({
      onCompleted: ({ requestUpgrade }) => {
        if (requestUpgrade.success)
          snackbar.setSuccessMessage(
            requestUpgrade.message ||
              t('Upgrade request sent! We will contact you shortly'),
          );
        else
          snackbar.setErrorMessage(
            requestUpgrade.message ||
              t('There was an error requesting the upgrade. Please try again later'),
          );
      },
    });
  };

  const CustomSnackbar = ({ severity, message, handleClose }: customSnackbarProps) => (
    <Snackbar
      open={!!message}
      onClose={handleClose}
      data-cy={`${severity}-snackbar`}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={severity === 'error' ? Zoom : undefined}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbar.setSuccessMessage(null);
    snackbar.setErrorMessage(null);
    snackbar.setWarningMessage(null);
  };

  return (
    <>
      <NavBar
        currentPage={active}
        isSmallDevice={isSmallDevice}
        isMobile={isMobile}
        openNavbar={openNavbar}
        setOpenNavbar={setOpenNavbar}
      />
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          transition: 'all 0.2s ease-in-out',
        }}
      >
        {isMobile && <Toolbar />}
        <Outlet />
      </Box>
      {snackbarTypes.map((snackbarType) => (
        <CustomSnackbar
          key={snackbarType.severity}
          severity={snackbarType.severity as AlertColor}
          message={snackbarType.message}
          handleClose={handleClose}
        />
      ))}
      {user?.activeTrial && new Date(user.trialEnd) < new Date() && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="info">
            <AlertTitle>
              <strong>{t('Trial period ended')}</strong>
            </AlertTitle>
            <Link onClick={handleRequestUpgrade} style={{ textDecoration: 'underline' }}>
              {t('Click here to subscribe and keep using magnettu.')}
            </Link>
          </Alert>
        </Snackbar>
      )}
      {user?.plan && user?.plan.includes(UserPlan.Inactive) && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert
            severity="info"
            action={
              <Button color="inherit" onClick={() => navigate('/settings')}>
                {t('Go to Settings')}
              </Button>
            }
          >
            <strong>{t("You don't have any plan assigned.")}</strong>{' '}
            {t(
              'Go to settings to upgrade your account or contact us at info@magnettu.com',
            )}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Dashboard;
