import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { BrandInput } from '../components/BrandComponents/BrandData';
import { Brand, Language, UserRole } from '../__generated__/graphql';
import { BrandContext } from '../context/BrandContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { GET_USERS_BY_COMPANY } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { AuthContext } from '../context/AuthContext';

interface Props {
  schema: any;
  brandData: Brand | null;
}

const useBrandForm = ({ schema, brandData }: Props) => {
  const { user } = useContext(AuthContext);
  const { dataBrands } = useContext(BrandContext);
  const { data: dataUsers } = useQuery(GET_USERS_BY_COMPANY, {
    variables: {
      filter: {
        deleted: true,
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !user?.role.includes(UserRole.BrandManager) &&
      !user?.role.includes(UserRole.LimitedBrandManager),
  });

  const users = [...dataUsers?.colleagueUsers || [], user];
  const userFound = users?.find((user) => user?._id === brandData?.userId);

  const companyBrandFound = dataBrands.find(
    (brand) => brand._id === brandData?.companyBrandId,
  );

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BrandInput>({
    defaultValues: {
      requiredApproval: '',
      connectLinkedin: '',
      name: '',
      requireContent: '',
      userBrand: {
        id: '',
        label: '',
      },
      associatedBrand: '',
      industry: '',
      subIndustry: '',
      competitiveAdvantage: '',
      hashtags: '',
      competitors: '',
      negatives: '',
      pageUrn: '',
      companyBrandId: {
        id: '',
        label: '',
      },
      associatedCorporateBrand: '',
      language: Language.English,
      position: '',
      targetBrand: [],
      targetPublic: '',
      otherIndustries: '',
      themeTalk: '',
      toneVoice: [],
      responsabilities: '',
      perspective: '',
      numberEmojis: 0,
      numberHashtags: 0,
      textLength: '',
      themeExtra: '',
      uniqueBrand: '',
      themeNegative: '',
      corporatePostFirstPart: [],
      corporatePostSecondPart: [],
      corporatePostThirdPart: [],
      otherCorporatePostFirstPart: '',
      otherCorporatePostSecondPart: '',
      otherCorporatePostThirdPart: '',
      firstPost: '',
      secondPost: '',
      thirdPost: '',
      fourthPost: '',
      contentConsumption: [],
      otherContentConsumption: '',
      worldLeaderInspiration: '',
      industryLeaderInspiration: '',
      hobbies: '',
      artistsInspiration: '',
      valuesBrand: '',
      corporateValues: '',
      politicalSocialActivityBrand: '',
      trendingTopics: '',
      trustedMedia: '',
      goalsBrand: [],
      otherGoalsBrand: '',
      corporateIdeas: '',
      corporateTarget: '',
      corporateVoice: [],
      urlProfile: '',
    },
    values: {
      urlProfile: brandData?.urlProfile || '',
      requiredApproval: '',
      connectLinkedin: !!brandData ? 'true' : 'false',
      associatedCorporateBrand: brandData?.companyBrandId ? 'true' : 'false',
      name: brandData?.name || '',
      requireContent: brandData?.contentCreation?.requireContent ? 'true' : 'false',
      userBrand: {
        id: userFound?._id || '',
        label: userFound?.firstName
          ? `${userFound.firstName} ${userFound.lastName}`
          : userFound?.email || '',
      },
      associatedBrand: brandData?.userId ? 'true' : 'false',
      industry: brandData?.contentCreation?.industry || '',
      competitiveAdvantage: brandData?.contentCreation?.competitiveAdvantage || '',
      hashtags: brandData?.contentCreation?.hashtags || '',
      competitors: brandData?.contentCreation?.competitors || '',
      negatives: brandData?.contentCreation?.negatives || '',
      pageUrn: '',
      companyBrandId: {
        id: companyBrandFound?._id || '',
        label: companyBrandFound?.name || '',
      },
      language: brandData?.contentCreation?.language || Language.English,
      position: brandData?.contentCreation?.position || '',
      targetBrand: (brandData?.contentCreation?.targetBrand as string[]) || [],
      targetPublic: brandData?.contentCreation?.targetPublic || '',
      subIndustry: brandData?.contentCreation?.subIndustry || '',
      otherIndustries: brandData?.contentCreation?.otherIndustries || '',
      themeTalk: brandData?.contentCreation?.themeTalk || '',
      toneVoice: (brandData?.contentCreation?.toneVoice as string[]) || [],
      corporateVoice: (brandData?.contentCreation?.corporateVoice as string[]) || [],
      goalsBrand: (brandData?.contentCreation?.goalsBrand as string[]) ?? [],
      otherGoalsBrand: brandData?.contentCreation?.otherGoalsBrand || '',
      valuesBrand: brandData?.contentCreation?.valuesBrand || '',
      otherCorporatePostFirstPart:
        brandData?.contentCreation?.otherCorporatePostFirstPart || '',
      otherCorporatePostSecondPart:
        brandData?.contentCreation?.otherCorporatePostSecondPart || '',
      otherCorporatePostThirdPart:
        brandData?.contentCreation?.otherCorporatePostThirdPart || '',
      worldLeaderInspiration: brandData?.contentCreation?.worldLeaderInspiration || '',
      industryLeaderInspiration:
        brandData?.contentCreation?.industryLeaderInspiration || '',
      hobbies: brandData?.contentCreation?.hobbies || '',
      artistsInspiration: brandData?.contentCreation?.artistsInspiration || '',
      contentConsumption:
        (brandData?.contentCreation?.contentConsumption as string[]) ?? [],
      otherContentConsumption: brandData?.contentCreation?.otherContentConsumption || '',
      politicalSocialActivityBrand:
        brandData?.contentCreation?.politicalSocialActivityBrand || '',
      trendingTopics: brandData?.contentCreation?.trendingTopics || '',
      trustedMedia: brandData?.contentCreation?.trustedMedia || '',
      firstPost: brandData?.contentCreation?.firstPost || '',
      secondPost: brandData?.contentCreation?.secondPost || '',
      thirdPost: brandData?.contentCreation?.thirdPost || '',
      fourthPost: brandData?.contentCreation?.fourthPost || '',
      themeExtra: brandData?.contentCreation?.themeExtra || '',
      themeNegative: brandData?.contentCreation?.themeNegative || '',
      corporatePostFirstPart:
        (brandData?.contentCreation?.corporatePostFirstPart as string[]) ?? [],
      corporatePostSecondPart:
        (brandData?.contentCreation?.corporatePostSecondPart as string[]) ?? [],
      corporatePostThirdPart:
        (brandData?.contentCreation?.corporatePostThirdPart as string[]) ?? [],
      numberEmojis: brandData?.contentCreation?.numberEmojis || 0,
      numberHashtags: brandData?.contentCreation?.numberHashtags || 0,
      textLength: brandData?.contentCreation?.textLength || '',
      responsabilities: brandData?.contentCreation?.responsabilities || '',
      uniqueBrand: brandData?.contentCreation?.uniqueBrand || '',
      perspective: brandData?.contentCreation?.perspective || '',
      corporateTarget: brandData?.contentCreation?.corporateTarget || '',
      corporateIdeas: brandData?.contentCreation?.corporateIdeas || '',
      corporateValues: brandData?.contentCreation?.corporateValues || '',
    },
    resolver: yupResolver(schema as any),
  });

  const watchAllFields = {
    watchAssociatedBrand: watch('associatedBrand') === 'true',
    watchAssociatedCorporateBrand: watch('associatedCorporateBrand') === 'true',
    watchConnectLinkedin: watch('connectLinkedin') === 'true',
    wathcPageUrn: watch('pageUrn'),
    watchIndustry: watch('industry'),
    watchSubIndustry: watch('subIndustry'),
    watchGoalsBrand: watch('goalsBrand'),
    watchName: watch('name'),
    watchUserBrand: watch('userBrand'),
  };

  return {
    control,
    watch,
    handleSubmit,
    errors,
    watchAllFields,
    setValue,
  };
};

export default useBrandForm;
