import { Language } from "../../__generated__/graphql";

//Opciones de Inputs

interface OptionsIndustries {
  [key: string]: string[];
}

export const optionsIndustries: OptionsIndustries = {
  'Technology and IT': [
    'Software and application development',
    'IT services and consulting',
    'Hardware and electronic equipment',
    'Internet and new media',
    'Others',
  ],
  'Health and pharmaceutical': [
    'Hospitals and clinics',
    'Drug development and manufacturing',
    'Biotechnology',
    'Home health care services',
    'Others',
  ],
  'Finance and insurance': [
    'Banking and financial services',
    'Insurance and reinsurance',
    'Fintech and payment solutions',
    'Financial and investment advising',
    'Others',
  ],
  'Education and training': [
    'Educational institutions (primary, secondary, higher)',
    'Professional and technical training',
    'E-learning and distance education',
    'Tutoring and coaching services',
    'Others',
  ],
  'Manufacturing and industry': [
    'Industrial and manufacturing production',
    'Automotive and components',
    'Food and beverages',
    'Textiles and apparel',
    'Others',
  ],
  'Retail and e-commerce': [
    'Retail in physical stores',
    'E-commerce and online platforms',
    'Retail distribution and logistics',
    'Fashion and accessories',
    'Others',
  ],
  'Hospitality and tourism': [
    'Hotels and accommodation',
    'Travel agencies and tour operators',
    'Restaurants and food services',
    'Tourist attractions and leisure',
    'Others',
  ],
  'Construction and engineering': [
    'Building construction and civil works',
    'Engineering and architecture services',
    'Infrastructure and public projects',
    'Maintenance and renovation',
    'Others',
  ],
  'Transportation and logistics': [
    'Land, sea, and air transportation',
    'Logistics and storage',
    'Postal and courier services',
    'Supply chain management',
    'Others',
  ],
  'Media and entertainment': [
    'Media production and distribution',
    'Cinema and television',
    'Gaming and digital entertainment',
    'Advertising and marketing',
    'Others',
  ],
  'Energy and natural resources': [
    'Oil, gas, and mining',
    'Renewable energies and sustainability',
    'Water and waste management',
    'Electricity and utilities',
    'Others',
  ],
  'Public sector and government': [
    'Public administration and regulation',
    'Defense and security',
    'Social policy and employment',
    'Urban planning and community development',
    'Others',
  ],
  'NGOs and social sector': [
    'Non-profit corporates and volunteering',
    'International development and humanitarian aid',
    'Social education and research',
    'Health and social well-being',
    'Others',
  ],
  'Other industry': [],
};


export const suggestionsToneOfVoice = [
  'Professional',
  'Formal',
  'Friendly',
  'Inspiring',
  'Motivational',
  'Fun',
  'Educational',
  'Informative',
  'Persuasive',
  'Calm',
  'Direct',
  'Expert',
  'Creative',
];

export const goalsBrandOptions = [
  'Retain talent',
  'Attract talent',
  'Sell',
  'Boost my brand',
  'Education and training',
  'Inspire',
  'Loyalize customers',
  'Other',
];

export const objectivesOptions = [
  'Sell',
  'Recruit',
  'Become a thought leader',
  'Maximize the reach of the company I work for',
  'Be a brand ambassador',
  'Inform and share my experience',
  'Expand my networking and community engagement.',
];

export const perspectiveOptions = [
  'First person (Uses pronouns like "I" and "we".)',
  'Second person (Directly to the reader as "you".)',
  'Third person (Using pronouns like "he", "she", or "they".)',
];

export const textLengthOptions = [
  'Short (about 200 words)',
  'Medium (about 450 words)',
  'Long (about 800 words)',
];

export const firstPartOptions = [
  'Hook',
  'Paragraph',
  'Quote',
  'Rhetorical question',
  'List',
  'Other (specify)',
];

export const secondPartOptions = [
  'Paragraph',
  'List of items',
  'Lesson',
  'Explanation',
  'Statistics / Data',
  'Other (specify)',
];

export const thirdPartOptions = [
  'Open-ended questions to invite reflection',
  'Debate questions to encourage discussion',
  'Specific actions (e.g., "Subscribe", "Download here", "Sign up now")',
  'Links to visit websites or see more content',
  'Invitations to events or webinars',
  'Other (specify)',
];

export const generatePostOptions = [
  'Personal Post (Customized based on experiences, events, etc. from your personal brand)',
  'Corporate Post (Post based on what your company sells and how your personal brand relates to your company)',
  'News (Relevant and current topics of interest to your industry)',
  'Trends (Topics being discussed and delved into with personal insights)',
];

export const typeContentConsumedOptions = [
  'News articles or blogs',
  'Videos (tutorials, informative, entertainment)',
  'Podcasts or audiobooks',
  'Social media posts (Facebook, Twitter, LinkedIn, etc.)',
  'Books (digital or print)',
  'Magazines or newspapers (digital or print)',
  'Interactive content (webinars, online courses, etc.)',
  'Other (please specify)',
];
// Interface global de Inputs de Brand

export interface BrandInput {
  name: string;
  urlProfile: string;
  connectLinkedin: string;
  requiredApproval: string;
  requireContent: string;
  userBrand: {
    id: string;
    label: string;
  }
  associatedBrand: string;
  industry: string;
  subIndustry: string;
  otherIndustries: string;
  competitiveAdvantage: string;
  hashtags: string;
  competitors: string;
  negatives: string;
  pageUrn: string;
  companyBrandId: {
    id: string;
    label: string;
  }
  associatedCorporateBrand: string;
  language: Language;
  position: string;
  targetBrand: string[];
  targetPublic: string;
  themeTalk: string;
  toneVoice: string[];
  corporateVoice: string[];
  responsabilities?: string;
  perspective?: string;
  numberEmojis?: number;
  numberHashtags?: number;
  textLength?: string;
  themeExtra?: string;
  uniqueBrand?: string;
  themeNegative?: string;
  corporatePostFirstPart?: string[];
  corporatePostSecondPart?: string[];
  corporatePostThirdPart?: string[];
  otherCorporatePostFirstPart?: string;
  otherCorporatePostSecondPart?: string;
  otherCorporatePostThirdPart?: string;
  firstPost?: string;
  secondPost?: string;
  thirdPost?: string;
  fourthPost?: string;
  contentConsumption?: string[];
  otherContentConsumption?: string;
  worldLeaderInspiration?: string;
  industryLeaderInspiration?: string;
  hobbies?: string;
  artistsInspiration?: string;
  valuesBrand?: string;
  corporateValues?: string;
  politicalSocialActivityBrand?: string;
  trendingTopics?: string;
  trustedMedia?: string;
  goalsBrand?: string[];
  otherGoalsBrand?: string;
  corporateTarget?: string;
  corporateIdeas?: string;

}