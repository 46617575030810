import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en/translation.json';
import es from '../locales/es/translation.json';

// Retrieve the saved language from localStorage, default to 'en' if none exists
const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
  },
  lng: savedLanguage, // Use the saved language from localStorage
  fallbackLng: 'en', // Fallback language if translation is missing
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
