import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CSSObject,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Drawer as MuiDrawer,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { getAuth } from 'firebase/auth';
import { forwardRef, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Messages, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { PopupNotificationContext } from '../../context/PopupNotificationContext';
import { GET_BRANDS } from '../../graphql/queries';
import { RoutesNames } from '../../types';
import { formatDate, getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';
import BrandModal from '../BrandComponents/BrandModal';
import DrawerListItem from '../DrawerListItem/DrawerListItem';
import LinkedinPageSelector from '../SocialMediaAccounts.tsx/LinkedinPageSelector';
import { ChangeContentBoardButton } from './ChangeContentBoardButton';
import { NavBarMobile } from './NavbarMobile';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerDesktop = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface Props {
  currentPage: RoutesNames;
  isSmallDevice?: boolean;
  isMobile?: boolean;
  openNavbar: boolean;
  setOpenNavbar: (open: boolean) => void;
}

const NavBar = ({
  currentPage,
  isSmallDevice,
  isMobile,
  openNavbar,
  setOpenNavbar,
}: Props) => {
  const { t } = useTranslation();
  const { user, setUser, changeBrand } = useContext(AuthContext);
  const { handleRenewAccess, handleBrandSelected } = useContext(BrandContext);
  const {
    handleSaveLinkedinCompanyCredentials,
    setLinkedinPageSelectorOpen,
    pages,
    modalNewBrand,
    handleCloseModal,
    handleOpenModal,
    openModalCompany,
    setOpenModalCompany,
  } = useContext(BrandContext);

  const {
    popupMessages,
    handleClose,
    handleReview,
    handleReviewLater,
    brandsToReconnect,
    handleReconnectLinkedinReminder,
  } = useContext(PopupNotificationContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState<null | HTMLElement>(null);
  const [searchInput, setSearchInput] = useState<string>('');

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
  };

  const { data: brandsData } = useQuery(GET_BRANDS, {
    variables: {},
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
    fetchPolicy: 'network-only',
  });

  const brands =
    brandsData?.getBrands.filter((brand) =>
      brand?.name.toLowerCase().includes(searchInput.toLowerCase()),
    ) || [];

  const userBrand = brands.find((brand) => brand?._id === user?.brandId);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSearchInput('');
  };

  const handleSelectBrand = (brandId: string) => {
    handleCloseMenu();
    changeBrand(brandId);
  };

  const onClickDrawerItem = (route: RoutesNames) => {
    if (anchorElAvatar) setAnchorElAvatar(null);
    navigate(route);
  };

  const onClickLogout = () => {
    setUser(null);
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    const auth = getAuth();
    auth.signOut();
    navigate('/login');
  };

  const createItems = [
    ...(!user?.role?.includes(UserRole.BrandManager) &&
    !user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Content Board'),
            iconName: 'GridView',
            route: '',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.InsightsViewer)
      ? [
          {
            text: t('Inspiration'),
            iconName: 'Insights',
            route: 'insights',
          },
        ]
      : []),
    ...(!user?.role?.includes(UserRole.BrandManager) &&
    !user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Calendar'),
            iconName: 'CalendarToday',
            route: 'calendar',
          },
        ]
      : []),

    {
      text: t('Analytics'),
      iconName: 'PieChartOutline',
      route: 'analytics',
    },
  ];

  const manageCreateItems = [
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Content Manager'),
            iconName: 'Collections',
            route: 'manage-team',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.AdvocacyManager)
      ? [
          {
            text: t('Advocacy'),
            iconName: 'ConnectWithoutContact',
            route: 'advocacy',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Brands'),
            iconName: 'Group',
            route: 'brands',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.GroupMembersEditor)
      ? [
          {
            text: t('Members'),
            iconName: 'Tune',
            route: 'groups-members',
          },
        ]
      : []),
  ];

  const analysisCreateItems = [
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Brands Activity'),
            iconName: 'Diversity3',
            route: 'brands-activity',
          },
          {
            text: t('Brands Content Table'),
            iconName: 'Star',
            route: 'top-content',
          },
        ]
      : []),
  ];

  const adminItems = [
    ...(user?.role?.includes(UserRole.Admin)
      ? [
          {
            text: t('Client'),
            iconName: 'PersonAdd',
            route: 'clients',
          },
          {
            text: t('Users'),
            iconName: 'Group',
            route: 'users',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.Admin)
      ? [
          {
            text: t('Content Admin'),
            iconName: 'Collections',
            route: 'content-manager',
          },
        ]
      : []),
  ];

  const buildPopupMessage = (message: Messages) => {
    return (
      <Dialog
        disableEscapeKeyDown
        key={message._id}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(message)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{message.title}</DialogTitle>
        <DialogContent>
          {message.image && (
            <CardMedia
              component={message.image.startsWith('video') ? 'video' : 'img'}
              controls
              image={message.image}
              sx={{
                marginBottom: 1,
                height: '250x',
                width: '250px',
                display: 'flex',
                marginX: 'auto',
              }}
              data-testid="post-card-media"
            />
          )}
          <DialogContentText id="alert-dialog-slide-description">
            {message.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(message)}>{t('Dismiss')}</Button>
          <Button variant="outlined" onClick={() => handleReviewLater(message)}>
            {t('Remind me later')}
          </Button>
          <Button variant="contained" onClick={() => handleReview(message)}>
            {t('Open')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const reconnectLinkedInPopup = brandsToReconnect.length > 0 && (
    <Dialog
      disableEscapeKeyDown
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
    >
      <DialogTitle>
        {brandsToReconnect.length > 1
          ? t('LinkedInConnectionExpirePlural')
          : t('LinkedInConnectionExpireSingular')}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {brandsToReconnect.map((brand) => (
            <Stack direction="row" alignItems="center" spacing={2} key={brand._id}>
              <Avatar avatar={brand.account?.avatar || ''} name={brand.name} />
              <Stack direction="column" sx={{ flexGrow: 1 }}>
                <Typography variant="body1">{brand.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('Access expires on')} {formatDate(new Date(brand.account?.exp))}
                </Typography>
              </Stack>
              <Button
                variant="outlined"
                onClick={() => {
                  handleBrandSelected(brand);
                  handleRenewAccess(brand.account?.type!, window.location.pathname);
                }}
              >
                {t('Renew access')}
              </Button>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReconnectLinkedinReminder}>{t('Remind me later')}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          transform: isMobile ? 'translate(0px, 50px)' : 'translate(25px, 10px)',
          '& .MuiPaper-root': {
            borderRadius: '8px',
            ...(isMobile
              ? {
                  width: '100%',
                  height: '50vh',
                }
              : {}),
          },
        }}
        elevation={10}
        MenuListProps={{
          sx: {
            height: 'auto',
            maxHeight: isMobile ? '100vh' : 410,
            width: isMobile ? '100%' : 245,
            minHeight: 200,
          },
        }}
      >
        <Typography
          sx={{
            paddingLeft: '20px',
            color: 'rgba(194, 11, 99, 1)',
            marginY: '0.5rem',
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          {t('Change brand')}
        </Typography>

        <Box width="100%" display="flex" justifyContent="center">
          <TextField
            sx={{
              width: '90%',
            }}
            placeholder={t('Search')}
            onChange={onSearch}
            InputProps={{
              style: {
                height: '40px',
                borderRadius: '8px',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            paddingLeft: '1.5rem',
            background: 'rgba(236, 236, 236, 1)',
            marginY: 1,
            '&:last-child td, &:last-child th': { border: 0 },
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            gap: 1,
          }}
          onClick={handleOpenModal}
        >
          <AddIcon
            sx={{
              fontSize: '24px',
            }}
          />
          <Typography fontSize={14}>{t('Create new brand')}</Typography>
        </Box>

        {userBrand && (
          <Box
            display="flex"
            key={userBrand?._id}
            width="100%"
            paddingLeft="1.5rem"
            alignItems="center"
            gap={1}
            marginTop="0.3rem"
            onClick={() => {
              handleSelectBrand(userBrand?._id!);
            }}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                user?.brandSelectedId === userBrand?._id
                  ? 'rgba(255, 0, 122, 0.08)'
                  : 'white',
              height: '56px',
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 122, 0.08)',
              },
            }}
          >
            <Avatar
              name={userBrand?.name || ''}
              avatar={userBrand?.account?.avatar || undefined}
              backgroundColor={userBrand?.backgroundColor || undefined}
              size={40}
            />
            <Typography fontSize="14px" fontWeight="bold">
              {userBrand.name}
            </Typography>
          </Box>
        )}
        <Divider variant="fullWidth" sx={{ marginY: 1 }} />
        {brands
          .filter((brand) => brand?._id !== user?.brandId)
          .map((brand) => (
            <Box
              display="flex"
              key={brand?._id}
              width="100%"
              paddingLeft="1.5rem"
              alignItems="center"
              gap={1}
              marginTop="0.3rem"
              onClick={() => {
                handleSelectBrand(brand?._id!);
              }}
              sx={{
                cursor: 'pointer',
                backgroundColor:
                  user?.brandSelectedId === brand?._id
                    ? 'rgba(255, 0, 122, 0.08)'
                    : 'white',
                height: '56px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 0, 122, 0.08)',
                },
              }}
            >
              <Avatar
                name={brand?.name || ''}
                backgroundColor={brand?.backgroundColor || undefined}
                avatar={brand?.account?.avatar || undefined}
                size={40}
              />
              <Typography
                fontSize={user?.brandSelectedId === brand?._id ? '15px' : '14px'}
                sx={{
                  color: user?.brandSelectedId === brand?._id ? '#C20B63' : 'black',
                  fontWeight: user?.brandSelectedId === brand?._id ? 700 : 'normal',
                }}
              >
                {brand?.name}
              </Typography>
            </Box>
          ))}
      </Menu>

      {isMobile ? (
        <NavBarMobile
          brandSelect={user?.brandSelected || null}
          userSelectBackgroundColor={''}
          onBrandSelect={(brand, background) => handleSelectBrand(brand._id)}
          onChangeContentBoardClick={(e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          anchorElement={anchorEl}
          currentPage={currentPage}
          onClickLogout={onClickLogout}
        />
      ) : (
        <DrawerDesktop
          variant="permanent"
          open={openNavbar}
          PaperProps={{
            sx: {
              boxShadow: '0px 10px 24px 4px #4F4F4F1F',
              borderRadius: '0px 9px 9px 0px !important',
              border: 'none',
            },
          }}
        >
          <DrawerHeader>
            <Box
              display={openNavbar ? 'flex' : 'none'}
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <img
                src={
                  user?.shell?.whiteLabel
                    ? user.shell.logo || '/images/magnettu_logo.svg'
                    : '/images/magnettu_logo.svg'
                }
                alt="magnettu logo"
                width="130px"
              />
            </Box>

            <IconButton
              color="primary"
              onClick={() => setOpenNavbar(!openNavbar)}
              sx={{
                marginTop: '5px',
                marginX: !openNavbar ? 'auto' : '0px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {openNavbar ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Stack justifyContent="space-between" height="100%">
            <Stack>
              <List
                sx={{
                  ...(user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)
                    ? { backgroundColor: 'rgba(245, 245, 245, 1)' }
                    : {}),
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                {(user?.role?.includes(UserRole.BrandManager) ||
                  user?.role?.includes(UserRole.LimitedBrandManager)) && (
                  <ListItem disablePadding sx={{ width: '90%', marginX: 'auto', mb: 2 }}>
                    {!brands.length ? (
                      <Button
                        variant="outlined"
                        sx={{
                          width: '100%',
                          backgroundColor: '#FFF',
                          height: '35px',
                          borderRadius: '25px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={handleOpenModal}
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight={700}
                          sx={{
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {t('Create brand')}
                        </Typography>
                        <AddIcon
                          sx={{
                            fontSize: '24px',
                            marginLeft: 'auto',
                          }}
                        />
                      </Button>
                    ) : (
                      <ChangeContentBoardButton
                        variant={openNavbar ? 'standard' : 'compact'}
                        onClick={(e) => {
                          setAnchorEl(anchorEl ? null : e.currentTarget);
                        }}
                        anchorElement={anchorEl}
                        brand={user.brandSelected || null}
                      />
                    )}
                  </ListItem>
                )}
                {openNavbar &&
                  !user?.role?.includes(UserRole.BrandManager) &&
                  !user?.role?.includes(UserRole.LimitedBrandManager) && (
                    <Typography
                      fontSize="11px"
                      fontWeight={600}
                      marginLeft="1rem"
                      marginBottom="1rem"
                      marginTop="0.5rem"
                    >
                      {t('CONTENT')}
                    </Typography>
                  )}

                {createItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => {
                      if (
                        user?.brand ||
                        user?.role.includes(UserRole.BrandManager) ||
                        user?.role.includes(UserRole.LimitedBrandManager) ||
                        user?.role.includes(UserRole.Admin)
                      )
                        onClickDrawerItem(route as RoutesNames);
                    }}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}
                {user?.brandSelected?.contentCreation?.requireContent && (
                  <DrawerListItem
                    tooltip={t('You can send us content requests through Whatsapp')}
                    text={openNavbar ? 'WhatsApp' : ''}
                    iconName={'WhatsApp'}
                    onClick={() =>
                      window.open(
                        `https://wa.me/34663937183?text=${encodeURIComponent(
                          t(
                            `Hola magnettu soy ${user.firstName} ${user.lastName} y quería pedirte que me ayudes para que en la marca de ${user.brandSelected?.name} ...`,
                          ),
                        )}`,
                        '_blank',
                      )
                    }
                    isSmallDevice={isSmallDevice}
                  />
                )}

                {(user && user.customMenus && user.customMenus?.length !== 0
                  ? user.customMenus!!.map((menu) => ({
                      text: menu?.title as string,
                      iconName: menu?.icon as string,
                      route: menu?.url,
                    }))
                  : []
                ).map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => {
                      if (route) window.open(route, '_blank');
                    }}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                    rotateIcon={text === 'Groups & Members'}
                  />
                ))}
              </List>
              <List>
                {manageCreateItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('MANAGE')}
                  </Typography>
                )}
                {manageCreateItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}

                {analysisCreateItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('ANALYSIS')}
                  </Typography>
                )}
                {analysisCreateItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}
                {adminItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('MAGNETTU ADMIN')}
                  </Typography>
                )}
                {adminItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}
              </List>
            </Stack>
            <Stack>
              <DrawerListItem
                text={openNavbar ? t('Help') : ''}
                iconName={'HelpOutline'}
                onClick={() =>
                  window.open(
                    'https://magnettu.notion.site/Ayuda-a7a72297741a4acca98d456591ed31e5',
                    '_blank',
                  )
                }
                isSmallDevice={isSmallDevice}
              />
              <Divider variant="fullWidth" sx={{ marginY: 1 }} />
              <List sx={{ paddingTop: 0 }}>
                <ListItem
                  key={user?._id}
                  disablePadding
                  sx={{ padding: 0, width: '90%', marginX: 'auto' }}
                >
                  <ListItemButton
                    sx={{ borderRadius: '9px' }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setAnchorElAvatar(anchorElAvatar ? null : event.currentTarget);
                    }}
                  >
                    <ListItemIcon sx={{ marginLeft: '6px' }}>
                      <Avatar
                        name={user?.firstName || user?.email || ''}
                        lastName={user?.lastName || undefined}
                        avatar={user?.avatar || undefined}
                      />
                    </ListItemIcon>
                    {openNavbar && (
                      <ListItemText sx={{ opacity: !openNavbar ? 0 : 1 }}>
                        <Stack>
                          <Typography variant="body1" fontSize={14}>
                            {getFullName(user).length > 20
                              ? `${getFullName(user).substring(0, 17)}...`
                              : getFullName(user)}
                          </Typography>
                          {user?.activeTrial && new Date(user?.trialEnd) < new Date() ? (
                            <Typography variant="caption">{`${t(
                              'Trial ends',
                            )} ${formatDate(new Date(user?.trialEnd))}`}</Typography>
                          ) : (
                            <Typography variant="caption">
                              {t('Active License')}
                            </Typography>
                          )}
                        </Stack>
                      </ListItemText>
                    )}
                  </ListItemButton>
                </ListItem>
              </List>
            </Stack>
          </Stack>
          {!!popupMessages.length && popupMessages.map(buildPopupMessage)}
          {reconnectLinkedInPopup}
          <LinkedinPageSelector
            open={openModalCompany}
            onClose={() => setOpenModalCompany(false)}
            onSubmit={(page) => {
              handleSaveLinkedinCompanyCredentials(page);
              setLinkedinPageSelectorOpen(null);
              setOpenModalCompany(false);
            }}
            pages={pages}
          />

          <Dialog
            open={modalNewBrand}
            onClose={handleCloseModal}
            PaperProps={{
              sx: {
                width: '800px',
                maxWidth: '800px !important',
              },
            }}
          >
            <BrandModal />
          </Dialog>
          <Menu
            anchorEl={anchorElAvatar}
            open={Boolean(anchorElAvatar)}
            onClose={() => setAnchorElAvatar(null)}
            PaperProps={{
              sx: {
                overflowY: 'auto',
                width: 245,
                height: 'auto',
                boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '5px',
                marginLeft: openNavbar ? '16rem' : '5rem',
                marginTop: '-2rem',
              },
            }}
          >
            <Stack>
              <List>
                <DrawerListItem
                  text={t('Settings')}
                  iconName={'Settings'}
                  onClick={() => onClickDrawerItem('settings')}
                />
                <DrawerListItem
                  text={t('Logout')}
                  iconName={'Logout'}
                  onClick={() => onClickLogout()}
                />
              </List>
            </Stack>
          </Menu>
        </DrawerDesktop>
      )}
    </>
  );
};

export default NavBar;
