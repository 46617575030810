import { useMutation, useQuery } from '@apollo/client';
import { Add, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { Tag, TagInput } from '../../__generated__/graphql';
import { ADD_TAG_TO_SHELL } from '../../graphql/mutations';
import { GET_TAGS_BY_SHELL } from '../../graphql/queries';
import { getRandomHexColor } from '../../utils';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';


const defaultTag: Tag = {
  name: '',
  internal: false,
  _id: '',
};

interface LabelModalProps {
  openTagModal: boolean;
  setOpenTagModal: (open: boolean) => void;
  handleAddTags: (tags: Tag[]) => void;
  tags?: Tag[] | [];
  shellId?: string | null;
}
const TagModal: FC<LabelModalProps> = ({
  openTagModal,
  setOpenTagModal,
  handleAddTags,
  tags = [],
  shellId,
}) => {
  const [showInputSearch, setShowInputSearch] = useState(true);
  const [showInputAdd, setShowInputAdd] = useState(false);
  const [tagFilter, setTagFilter] = useState<string>('');
  const [tagInput, setTagInput] = useState<TagInput>(defaultTag);
  const [tagSelected, setTagSelected] = useState<Tag[]>(tags);
  const {user} = useContext(AuthContext);

  const handleSelectLabel = (tag: Tag) => {
    const currentTags = tagSelected || [];

    if (currentTags.find((item) => item._id === tag._id)) {
      const newTags = currentTags.filter((item) => item._id !== tag._id);
      setTagSelected(newTags);
      handleAddTags(newTags);
    } else {
      const newTags = [...currentTags, tag];
      setTagSelected(newTags);
      handleAddTags(newTags);
    }
  };

  const [addTagToShell, { loading }] = useMutation(ADD_TAG_TO_SHELL);
  const { data: tagsShell, refetch, loading: loadingTags } = useQuery(GET_TAGS_BY_SHELL, {
    variables: {
      shellId: shellId || user?.shellId,
    },
    skip: !shellId && !user?.shellId,
    onCompleted: (data) => {
      if (data.getTagsByShell?.length === 0) {
        setShowInputAdd(true);
        setShowInputSearch(false);
      }
    },
  });

  const handleClose = () => {
    setShowInputAdd(false);
    setShowInputSearch(true);
    setTagSelected(tags);
    setTagFilter('');
    setOpenTagModal(false);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagFilter(e.target.value);
  };

  const handleOpenFilter = () => {
    setShowInputSearch(true);
    setShowInputAdd(false);
  };

  const handleOpenAdd = () => {
    setTagFilter('');
    setTagInput({
      color: getRandomHexColor(),
      ...defaultTag,
    });
    setShowInputSearch(false);
    setShowInputAdd(true);
  };

  const onNewLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput({ ...tagInput, name: e.target.value });
  };

  const filteredTags = tagsShell?.getTagsByShell?.filter((tag: Tag | null) => {
    return tag !== null && tag.name?.toLowerCase().includes(tagFilter.toLowerCase());
  });

  const handleSave = async () => {
    await addTagToShell({
      variables: {
        tag: {
          name: tagInput.name,
          color: tagInput.color,
          internal: false,
        },
        shellId: shellId,
      },
      onCompleted(data) {
        if (data.addTagToShell?.success) {
          refetch();
          setTagInput(defaultTag);
          handleSelectLabel(data.addTagToShell?.tag as Tag);
          setShowInputAdd(false);
          setShowInputSearch(true);
        }
      },
    });
  };
  const { t } = useTranslation();

  return (
    <Dialog
      open={openTagModal}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: '350px',
          padding: 2,
        },
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={2}
        alignItems="center"
      >
        <Typography>{t('Labels')}</Typography>
        {!!tagsShell?.getTagsByShell?.length && (
          <Stack flexDirection="row">
            <IconButton onClick={showInputSearch ? handleOpenAdd : handleOpenFilter}>
              {showInputSearch ? <Add /> : <Search />}
            </IconButton>
          </Stack>
        )}
      </Stack>
      {showInputSearch && (
        <>
          <TextField
            placeholder={t('Search labels')}
            onChange={onSearch}
            InputProps={{
              style: {
                height: '40px',
                borderRadius: '8px',
                // width: '320px',
                maxWidth: '320px',
                backgroundColor: 'rgba(244, 244, 244, 1)',
                marginBottom: '10px',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </>
      )}
      {showInputAdd && (
        <Box>
          {!tagsShell?.getTagsByShell?.length && (
            <Typography marginBottom={1}>Add your first label to get started</Typography>
          )}
          <Stack flexDirection="row" gap={1} marginBottom={1}>
            <TextField
              onChange={(e) => {
                setTagInput({ ...tagInput, color: e.target.value });
              }}
              value={tagInput.color}
              type="color"
              InputProps={{
                style: {
                  height: '40px',
                  borderRadius: '8px',
                  width: '50px',
                  backgroundColor: 'rgba(244, 244, 244, 1)',
                },
              }}
            />

            <TextField
              placeholder="Add label"
              onChange={onNewLabel}
              value={tagInput.name}
              InputProps={{
                style: {
                  height: '40px',
                  borderRadius: '8px',
                  width: '100%',
                  backgroundColor: 'rgba(244, 244, 244, 1)',
                },
              }}
              variant="outlined"
            />
            <LoadingButton
              variant="outlined"
              onClick={handleSave}
              disabled={!tagInput.name?.length}
              loading={loading}
            >
              {t('Save')}
            </LoadingButton>
          </Stack>
        </Box>
      )}
      {loadingTags && <CircularProgress sx={{
        marginX: 'auto',
        marginTop: '20px',
      }} />}
      <Box maxHeight="400px" overflow="auto">
        {filteredTags &&
          filteredTags.length > 0 &&
          [...filteredTags].reverse().map((tag, index) => {
            return (
              <Stack
                key={index}
                flexDirection="row"
                alignItems="center"
                onClick={() => handleSelectLabel(tag as Tag)}
                gap={2}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '4px',
                  marginBottom: 0.5,
                  paddingY: 0.5,
                  '&:hover': {
                    backgroundColor: '#F5F5F5',
                  },
                }}
              >
                <Checkbox
                  checked={!!tagSelected?.find((item) => item._id === tag?._id) || false}
                />
                <Box
                  sx={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: tag?.color,
                    borderRadius: '4px',
                    border: '1px solid #000000',
                  }}
                />
                <Typography>{tag?.name}</Typography>
              </Stack>
            );
          })}
      </Box>

      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagModal;
