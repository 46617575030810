import { Close, InsertLink } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { PostHandlers, PostStatus } from '../../hooks/usePost';
import { useTranslation } from 'react-i18next';

interface Props {
  status: PostStatus | undefined;
  handlers: PostHandlers | undefined;
  onClose: (force?: boolean) => void;
  handleCopyLink: () => void;
}

const RequestApprovalModal = ({ status, handlers, onClose, handleCopyLink }: Props) => {
  const handleClose = () => {
    handlers?.handleCloseRequestApprovalDialog();
    onClose(true);
  };
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      open={status!.requestApprovalDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          width: '100%',
          maxWidth: '720px',
        },
      }}
    >
      <DialogTitle>
        <Typography fontSize={25} fontWeight={700}>
          {t('Your post has been submitted for approval!')}
        </Typography>
        <Close
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Divider />
          <Box
            display="block"
            width="100%"
            alignItems="center"
            gap={1}
            sx={{
              // height: '56px',
              marginY: '10px',
              color: 'black',
            }}
          >
            <Typography fontWeight={500} fontSize={18}>
              {t(
                'The approver now has the post in their <b>Content Board</b> and has been notified.',
              )}
            </Typography>
          </Box>
          <Divider />
          <Typography fontWeight={500} marginTop={1}>
            {t(
              "You can share the post with others using the generated link. Don't worry, any changes made will be automatically reflected in the link.",
            )}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingX: '20px',
        }}
      >
        <LoadingButton
          onClick={handleCopyLink}
          variant="outlined"
          startIcon={<InsertLink fontSize="small" sx={{
            color: '#685E64',
          }} />}
          sx={{
            width: '100%',
            maxWidth: '140px',
            marginTop: '-10px',
            marginBottom: '15px',
            border: '1px solid #A69BA180',
            color: '#685E64',
            fontWeight: 800,
            '&:hover': {
              border: '1px solid #A69BA1',
              color: '#685E64',
              backgroundColor: '#F9F9F9',
            },
          }}
        >
          {t('Copy Link')}
        </LoadingButton>
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            width: '100%',
            maxWidth: '140px',
            // marginX: 'auto',
            marginTop: '-10px',
            marginBottom: '15px',
          }}
        >
          {t('Accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestApprovalModal;
