import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { FormContainer, SwitchElement } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UNSUBSCRIBE_USER, SUBSCRIBE_USER } from '../../graphql/mutations';
import SettingsSection from '../SettingsSection/SettingsSection';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

const SettingsNotifications = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [enableNotifications, setEnableNotifications] = useState<boolean>(
    user?.notifications?.email?.allowed !== false,
  );
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const [UnsubscribeUser] = useMutation(UNSUBSCRIBE_USER, {
    onCompleted: ({ unsubscribeUserNotifications }) => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const [SubscribeUser] = useMutation(SUBSCRIBE_USER, {
    onCompleted: ({ subscribeUserNotifications }) => {
      setSuccessMessage(t('Notifications settings updated'));
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const valuesForm = {
    notifications: user?.notifications?.email?.allowed !== false,
  };

  type ValuesForm = typeof valuesForm;

  const onSuccess = (dataForm: ValuesForm) => {
    const userId = user?._id || '';
    enableNotifications
      ? SubscribeUser({ variables: { userId: userId } })
      : UnsubscribeUser({ variables: { userId: userId } });
  };

  return (
    <>
      <FormContainer onSuccess={onSuccess} values={valuesForm}>
        <SettingsSection
          title={t('Email')}
          subtitle={t('Opt out from receiving email updates')}
          divider={true}
        >
          <SwitchElement
            name="notifications"
            label={t('All notifications')}
            value={enableNotifications}
            onClick={() => setEnableNotifications(!enableNotifications)}
            data-testid="notifications-switch"
          />
        </SettingsSection>

        <Button
          variant="contained"
          type="submit"
          sx={inputStyle}
          data-testid="save-button-content-settings"
        >
          {t('Save')}
        </Button>
      </FormContainer>
    </>
  );
};

export default SettingsNotifications;
