import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Group } from '../../__generated__/graphql';
import AddIcon from '@mui/icons-material/Add';
import AvatarCell from '../Avatar/AvatarCell';
import { useTranslation } from 'react-i18next';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'black', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

interface Props {
  shellGroups: Group[];
  handleGroupSelected: (group: Group) => void;
  groupSelected: Group | null;
  setNewGroupModal: (value: boolean) => void;
}

const GroupsBrandTable = ({
  shellGroups,
  groupSelected,
  handleGroupSelected,
  setNewGroupModal,
}: Props) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '20px',
      }}
    >
      <Table
        sx={{
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            <HeaderCell text={t('Group name')} />
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            <TableRow
              sx={{
                background: 'rgba(236, 236, 236, 1)',
                marginBottom: 3,
                '&:last-child td, &:last-child th': { border: 0 },
              }}
              onClick={() => setNewGroupModal(true)}
            >
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  gap: 1,
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: '24px',
                  }}
                />
                <Typography fontSize={14}>{t('Add new group')}</Typography>
              </TableCell>
            </TableRow>
          </>
          {shellGroups?.map((group) => (
            <TableRow
              onClick={() => handleGroupSelected(group as Group)}
              key={group?._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor:
                  groupSelected?._id === group?._id ? 'rgba(255, 0, 122, 0.1)' : 'white',
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                height: '60px',
              }}
              data-testid="user-row"
            >
              <AvatarCell
                avatar={''}
                name={group.name}
                backgroundColor={group.backgroundColor || ''}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupsBrandTable;
