import { useTranslation } from 'react-i18next';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useState, useEffect } from 'react';

interface LanguageSwitcherProps {
  onChange: (language: string) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onChange }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language,
  ); // Set initial language based on localStorage

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage); // Update the state to reflect the language in localStorage
    }
  }, [i18n]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem('selectedLanguage', lng); // Save to localStorage
    onChange(lng); // Call the onChange prop
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox checked={language === 'en'} onChange={() => changeLanguage('en')} />
        }
        label={t('English')}
      />
      <FormControlLabel
        control={
          <Checkbox checked={language === 'es'} onChange={() => changeLanguage('es')} />
        }
        label={t('Spanish')}
      />
    </FormGroup>
  );
};

export default LanguageSwitcher;
