import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Brand, TypeBrand, User } from '../../__generated__/graphql';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useState } from 'react';
import { BrandContext } from '../../context/BrandContext';
import { AvatarGroup } from '../Avatar/AvatarGroup';
import { Close } from '@mui/icons-material';
import { getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';
import { useTranslation } from 'react-i18next';


const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'black', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

interface Props {
  filteredBrands: Brand[] | [];
  handleBrandSelected: (brand: Brand) => void;
  brandSelected: any;
}

const BrandsTable = ({ filteredBrands, handleBrandSelected, brandSelected }: Props) => {
  const { t } = useTranslation();
  const { setModalNewBrand } = useContext(BrandContext);
  const [openModal, setOpenModal] = useState(false);
  const [brandToModalSelected, setBrandToModalSelected] = useState<Brand | null>(null);

  const handleOpenModalAllBrands = (member: Brand) => {
    setOpenModal(true);
    setBrandToModalSelected(member);
  };

  const handleCloseModalAllBrands = () => {
    setOpenModal(false);
    setBrandToModalSelected(null);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Table
          sx={{
            width: '100%',
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell text={t('Brand name')} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredBrands?.length ? (
              <>
                <TableRow
                  sx={{
                    background: 'rgba(236, 236, 236, 1)',
                    marginBottom: 3,
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  onClick={() => setModalNewBrand(true)}
                >
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      justifyContent: 'center',
                      gap: 1,
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    }}
                  >
                    <AddIcon
                      sx={{
                        fontSize: '24px',
                      }}
                    />
                    <Typography fontSize={14}>Create new brand</Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    height: '50px',
                  }}
                />
              </>
            ) : (
              filteredBrands?.map((brand) => (
                <TableRow
                  onClick={() => handleBrandSelected(brand)}
                  key={brand._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor:
                      brandSelected?._id === brand._id
                        ? 'rgba(255, 0, 122, 0.1)'
                        : 'white',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  }}
                  data-testid="user-row"
                >
                  <TableCell>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Avatar
                          name={brand.name}
                          avatar={brand.account?.avatar || ''}
                          size={50}
                          backgroundColor={brand.backgroundColor || ''}
                        />
                        <Typography fontSize="12px" fontWeight="bold">
                          {brand.name}
                        </Typography>
                      </Stack>
                      <AvatarGroup
                        max={3}
                        titleTooltip="View all managers"
                        avatars={[
                          ...(brand.typeBrand === TypeBrand.Personal
                            ? [
                                {
                                  id: brand.user?._id || '',
                                  name: brand.user?.firstName
                                    ? `${brand.user?.firstName} ${brand.user?.lastName} (Assigned)`
                                    : brand.user?.email || 'Not assigned',
                                  avatar: '',
                                  backgroundColor: brand.userId ? brand.backgroundColor : '',
                                  active: true,
                                },
                              ]
                            : []),
                          ...(brand.brandManagers?.map((bm) => ({
                            id: bm?._id || '',
                            name: bm?.firstName
                              ? bm?.firstName + ' ' + bm?.lastName
                              : bm?.email,
                            avatar: bm?.brand?.account?.avatar || '',
                            backgroundColor: bm?.brand?.backgroundColor || '',
                            active: false,
                          })) as any),
                        ]}
                        onClickSurplus={() => {
                          handleOpenModalAllBrands(brand);
                        }}
                        size={30}
                        sizeAvatar={30}
                        separator={-0.5}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openModal}
        onClose={handleCloseModalAllBrands}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '555px' },
            height: 'auto',
            minHeight: '300px',
            padding: 2,
          },
        }}
      >
        <Stack>
          <Stack>
            <Typography fontWeight={700} fontSize={25}>
              All of {brandToModalSelected?.name} managers
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModalAllBrands}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Divider
            sx={{
              marginY: '1rem',
            }}
          />
          <Box maxHeight={400} overflow="auto">
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              gap={1}
              marginTop="0.3rem"
              sx={{
                backgroundColor: 'white',
                height: '56px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 0, 122, 0.08)',
                },
              }}
            >
              <Avatar
                name={getFullName(brandToModalSelected?.user as User)}
                avatar={brandToModalSelected?.user?.avatar || undefined}
                size={50}
              />
              <Typography
                fontSize={'14px'}
                sx={{
                  color: 'black',
                  fontWeight: 'normal',
                }}
              >
                {brandToModalSelected?.user
                  ? getFullName(brandToModalSelected?.user as User) + ' (Assigned)'
                  : 'Brand not assigned'}
              </Typography>
            </Box>
            {brandToModalSelected?.brandManagers?.map((user) => (
              <Box
                display="flex"
                key={user?._id}
                width="100%"
                alignItems="center"
                gap={1}
                marginTop="0.3rem"
                sx={{
                  backgroundColor: 'white',
                  height: '56px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 122, 0.08)',
                  },
                }}
              >
                <Avatar
                  name={getFullName(user as User)}
                  avatar={user?.brand?.account?.avatar || undefined}
                  size={50}
                />
                <Typography
                  fontSize={'14px'}
                  sx={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}
                >
                  {getFullName(user as User)}
                </Typography>
              </Box>
            ))}
          </Box>

          <Divider
            sx={{
              marginY: '1rem',
            }}
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default BrandsTable;
