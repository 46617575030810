import { useState, useLayoutEffect, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  useTheme,
  CircularProgress,
  Badge,
  Avatar,
} from '@mui/material';
import { Document, Page } from 'react-pdf';
import { Insight } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

const useWidth = (target: React.MutableRefObject<HTMLDivElement | null>) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (target.current) {
      setWidth(target.current.getBoundingClientRect().width);
    }
  }, [target]);

  return width;
};

interface Props {
  insight: Insight;
  handleReviewButton: () => void;
}

const InsightLinkedInCard = (props: Props) => {
  const { t } = useTranslation();
  const { insight, handleReviewButton } = props;
  const [loadImage, setLoadImage] = useState(!!!insight.imgUrl);
  const date = new Date(insight.pubDate).toLocaleDateString();
  const [mediaFailed, setMediaFailed] = useState(false);
  const cardMediaPdfRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const width = useWidth(cardMediaPdfRef);
  let media = insight.media || [];

  return (
    <Card
      sx={{
        border: 'solid 1px #EBEBEB',
      }}
      data-testid="insight-card"
    >
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignSelf="center" gap={2}>
            <Box display="flex">
              <Box maxHeight="100px">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <img
                      src="./images/icons/linkedin.png"
                      style={{ width: '20px', height: '20px' }}
                      alt="linkedin"
                    />
                  }
                >
                  <Avatar alt="profile" src={insight.author?.profilePicture || ''} />
                </Badge>
              </Box>
              <Box marginLeft="10px">
                <Typography variant="body2" color="text.primary" fontWeight={700}>
                  {insight.author?.firstName} {insight.author?.lastName}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {insight.author?.description}
                </Typography>
              </Box>
            </Box>

            <Typography variant="body2" color="text.primary" fontSize="14px">
              {date}
            </Typography>
          </Box>
        }
        // action={<Typography>{subtitle}</Typography>}
        titleTypographyProps={{
          variant: 'body2',
          color: 'text.primary',
        }}
      />
      <CardContent
        sx={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <Box marginY="1rem">
          {!!insight.keywords?.length &&
            insight.keywords?.slice(0, 4).map((keyword, idx) => (
              <Typography variant="subtitle2" key={idx} display="inline">
                #{keyword}{' '}
              </Typography>
            ))}
        </Box>

        <Typography mb={2}>
          {insight.description
            ? insight.description?.slice(0, 200) + '...'
            : insight.content?.slice(0, 200) + '...'}
        </Typography>
      </CardContent>

      {!!media.length && !mediaFailed && (
        <>
          {media[0].type?.includes('pdf') ? (
            <CardMedia
              ref={cardMediaPdfRef}
              onError={() => setMediaFailed(true)}
              sx={{ marginBottom: 1, height: 'auto', marginX: 'auto', width: '100%' }}
              data-testid="post-card-media"
              children={
                <>
                  <Box
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 3,
                      width: '50px',
                      justifyContent: 'center',
                      content: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography color={'white'} fontSize={'12px'} fontWeight={600}>
                      PDF
                    </Typography>
                  </Box>
                  <Document file={media[0].url} loading={<CircularProgress />}>
                    <Page
                      pageNumber={1}
                      scale={1}
                      width={width}
                      onLoadSuccess={() => setLoadImage(true)}
                    />
                  </Document>
                </>
              }
            />
          ) : (
            <CardMedia
              component={media[0]?.type?.startsWith('video') ? 'video' : 'img'}
              controls
              image={media[0]?.url || undefined}
              sx={{ marginBottom: 1, height: loadImage ? 'auto' : '100px' }}
              data-testid="post-card-media"
              onLoad={() => setLoadImage(true)}
              onLoadedData={() => setLoadImage(true)}
              onError={() => setMediaFailed(true)}
            />
          )}
        </>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        paddingX="10px"
        marginY="1rem"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <img
            src="./images/icons/interactions.png"
            style={{ width: '44px', height: '20px' }}
            alt="interactions"
          />
          <Typography
            variant="body2"
            color="text.primary"
            fontSize="12px"
            marginLeft="5px"
          >
            {insight.interactions?.numLikes || 0}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.primary" fontSize="12px">
          {insight.interactions?.comments}{' '}
          {insight.interactions?.comments === 1 ? 'comentario' : 'comentarios'}
        </Typography>
      </Box>
      <CardActions sx={{ paddingX: '10px', paddingBottom: '10px' }}>
        <Button
          fullWidth
          sx={{ backgroundColor: '#F7F7F7', border: 'none' }}
          onClick={handleReviewButton}
          data-testid="share-button"
        >
          <Typography fontWeight="bold">{t('View')}</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default InsightLinkedInCard;
